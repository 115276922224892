html {
  background-color: #090909;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Superstar';
  font-style: normal;
  line-height: normal;
  src:
    url('../src/assets/fonts/superstar.ttf') format('truetype');
}

@font-face {
  font-family: 'Avenir400';
  font-style: normal;
  line-height: normal;
  font-display: swap;
  src:
    url('../src/assets/fonts/avenir_regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Avenir600';
  font-style: normal;
  line-height: normal;
  font-display: swap;
  src:
    url('../src/assets/fonts/avenir_demi.ttf') format('truetype');
}

@keyframes moveLeft {
  0% {
    background-position: 0;
  }

  100% {
    background-position: -1680px;
  }
}

@keyframes moveLeftMobile {
  0% {
    background-position: 0;
  }

  100% {
    background-position: -840px;
  }
}
